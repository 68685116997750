import "/root/workspace/operator_ui_preview/node_modules/core-js/modules/es.array.iterator.js";
import "/root/workspace/operator_ui_preview/node_modules/core-js/modules/es.promise.js";
import "/root/workspace/operator_ui_preview/node_modules/core-js/modules/es.object.assign.js";
import "/root/workspace/operator_ui_preview/node_modules/core-js/modules/es.promise.finally.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import Vue from 'vue';
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css'; // a modern alternative to CSS resets
import VueClipBoard from 'vue-clipboard2';
import Element from 'element-ui';
import './styles/element-variables.scss';
import '@/styles/index.scss'; // global css
import '@/styles/admin.scss';
import VueCodemirror from 'vue-codemirror';
import 'codemirror/lib/codemirror.css';
Vue.use(VueCodemirror);
import App from './App';
import store from './store';
import router from './router';
import permission from './directive/permission';
import { getDicts } from '@/api/admin/dict/data';
import { getItems, pagingItems, setItems } from '@/api/table';
import { getConfigKey } from '@/api/admin/sys-config';
import { parseTime, formatYmd, resetForm, addDateRange, selectDictLabel, makeElementTree, coverZeroToUndefined, /* download,*/selectItemsLabel } from '@/utils/costum';
import './icons'; // icon
import './permission'; // permission control
import './utils/error-log'; // error log

import Viser from 'viser-vue';
Vue.use(Viser);
import * as filters from './filters'; // global filters

import Pagination from '@/components/Pagination';
import BasicLayout from '@/layout/BasicLayout';
import VueParticles from 'vue-particles';
Vue.use(VueParticles);
import '@/utils/dialog';

// 全局方法挂载
Vue.prototype.getDicts = getDicts;
Vue.prototype.getItems = getItems;
Vue.prototype.pagingItems = pagingItems;
Vue.prototype.setItems = setItems;
Vue.prototype.getConfigKey = getConfigKey;
Vue.prototype.parseTime = parseTime;
Vue.prototype.formatYmd = formatYmd;
Vue.prototype.resetForm = resetForm;
Vue.prototype.addDateRange = addDateRange;
Vue.prototype.selectDictLabel = selectDictLabel;
Vue.prototype.selectItemsLabel = selectItemsLabel;
Vue.prototype.makeElementTree = makeElementTree;
Vue.prototype.coverZeroToUndefined = coverZeroToUndefined;
// Vue.prototype.download = download

// 全局组件挂载
Vue.component('Pagination', Pagination);
Vue.component('BasicLayout', BasicLayout);
// select分页加载
Vue.directive('el-select-loadmore', {
  bind: function bind(el, binding) {
    var SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
    SELECTWRAP_DOM.addEventListener('scroll', function () {
      var condition = Math.floor(this.scrollHeight - this.scrollTop) <= this.clientHeight;
      if (condition) {
        binding.value();
      }
    });
  }
});
Vue.prototype.msgSuccess = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: 'success'
  });
};
Vue.prototype.msgError = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: 'error'
  });
};
Vue.prototype.msgInfo = function (msg) {
  this.$message.info(msg);
};
Vue.use(permission);
Vue.use(VueClipBoard);
Vue.use(Element, {
  size: Cookies.get('size') || 'medium' // set element-ui default size
});
import VueDND from 'awe-dnd';
Vue.use(VueDND);
import 'remixicon/fonts/remixicon.css';

// register global utility filters
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});