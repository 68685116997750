import "core-js/modules/es.function.name.js";
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  size: function size(state) {
    return state.app.size;
  },
  device: function device(state) {
    return state.app.device;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  name: function name(state) {
    return state.user.name;
  },
  introduction: function introduction(state) {
    return state.user.introduction;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  permisaction: function permisaction(state) {
    return state.user.permisaction;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  topbarRouters: function topbarRouters(state) {
    return state.permission.topbarRouters;
  },
  defaultRoutes: function defaultRoutes(state) {
    return state.permission.defaultRoutes;
  },
  sidebarRouters: function sidebarRouters(state) {
    return state.permission.sidebarRouters;
  },
  errorLogs: function errorLogs(state) {
    return state.errorLog.logs;
  },
  appInfo: function appInfo(state) {
    return state.system.info;
  },
  userInfo: function userInfo(state) {
    return state.user.userInfo;
  },
  isIntercept: function isIntercept(state) {
    return state.permission.isIntercept;
  },
  interceptData: function interceptData(state) {
    return state.permission.interceptData;
  }
};
export default getters;