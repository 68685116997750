import _typeof from "/root/workspace/operator_ui_preview/node_modules/@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/web.dom-collections.for-each.js";
// 日期格式化
export function parseTime(time, pattern) {
  if (arguments.length === 0 || !time) {
    return null;
  }
  if (time.indexOf('01-01-01') > -1) {
    return '-';
  }
  var format = pattern || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;
  if (_typeof(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, function (result, key) {
    var value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }
    return value || 0;
  });
  return time_str;
}
export var formatYmd = function formatYmd(date) {
  return date.toISOString().slice(0, 10) + 'T16:00:00.000Z';
};
// 表单重置
export function resetForm(refName) {
  if (this.$refs[refName]) {
    this.$refs[refName].resetFields();
  }
}

// 添加日期范围
export function addDateRange(params, dateRange) {
  var search = params;
  search.beginTime = '';
  search.endTime = '';
  if (dateRange != null && dateRange !== '') {
    search.beginTime = this.dateRange[0];
    search.endTime = this.dateRange[1];
  }
  return search;
}

// 回显数据字典
export function selectDictLabel(datas, value) {
  if (!datas) return null;
  var actions = [];
  Object.keys(datas).map(function (key) {
    if (datas[key].value === '' + value) {
      actions.push(datas[key].label);
      return false;
    }
  });
  return actions.join('');
}
export function selectItemsLabel(datas, value) {
  if (!datas) return null;
  var actions = [];
  Object.keys(datas).map(function (key) {
    if (datas[key].key === '' + value) {
      actions.push(datas[key].value);
      return false;
    }
  });
  return actions.join('');
}

// 字符串格式化(%s )
export function sprintf(str) {
  var args = arguments;
  var flag = true;
  var i = 1;
  str = str.replace(/%s/g, function () {
    var arg = args[i++];
    if (typeof arg === 'undefined') {
      flag = false;
      return '';
    }
    return arg;
  });
  return flag ? str : '';
}

// 转换字符串，undefined,null等转化为""
export function praseStrEmpty(str) {
  if (!str || str === 'undefined' || str === 'null') {
    return '';
  }
  return str;
}
export function makeElementTree(params) {
  var pid = params.pid,
    list = params.list,
    pidFiled = params.pidFiled,
    labelFiled = params.labelFiled,
    valueFiled = params.valueFiled;
  if (list.length === 1) {
    return [{
      label: list[0][labelFiled],
      value: list[0][valueFiled],
      id: undefined === list[0]['id'] ? 0 : list[0]['id'],
      name: list[0][labelFiled],
      status: undefined === list[0]['status'] ? 0 : list[0]['status']
    }];
  }
  var _makeTree = function makeTree(pid) {
    var arr = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    var res = [];
    arr.forEach(function (i) {
      if (i[pidFiled] === pid) {
        var children = _makeTree(i[valueFiled], list);
        var obj = {
          label: i[labelFiled],
          value: i[valueFiled],
          id: undefined === i['id'] ? 0 : i['id'],
          name: i[labelFiled],
          status: undefined === i['status'] ? 0 : i['status']
        };
        if (children.length) {
          obj.children = children;
        }
        res.push(obj);
      }
    });
    return res;
  };
  return _makeTree(pid, list);
}
export function coverZeroToUndefined(obj) {
  var keys = Object.keys(obj);
  keys.forEach(function (v) {
    obj[v] = [0, '0'].indexOf(obj[v]) > -1 ? undefined : obj[v];
  });
  return obj;
}
export function getAge(dateStr) {
  var returnAge;
  var strBirthdayArr = dateStr.split('-');
  var birthYear = parseInt(strBirthdayArr[0]);
  var birthMonth = parseInt(strBirthdayArr[1]);
  var birthDay = parseInt(strBirthdayArr[2]);
  var d = new Date();
  var nowYear = d.getFullYear();
  var nowMonth = d.getMonth() + 1;
  var nowDay = d.getDate();
  if (nowYear === birthYear) {
    returnAge = 0;
  } else {
    var ageDiff = nowYear - birthYear;
    if (ageDiff > 0) {
      if (nowMonth === birthMonth) {
        var dayDiff = nowDay - birthDay;
        if (dayDiff < 0) {
          returnAge = ageDiff - 1;
        } else {
          returnAge = ageDiff;
        }
      } else {
        var monthDiff = nowMonth - birthMonth;
        if (monthDiff < 0) {
          returnAge = ageDiff - 1;
        } else {
          returnAge = ageDiff;
        }
      }
    } else {
      returnAge = -1;
    }
  }
  return returnAge;
}
export function getBirthdayByIdCard(idCard) {
  var birthday = '';
  if (idCard != null && idCard !== '') {
    if (idCard.length === 15) {
      birthday = '19' + idCard.substr(6, 6);
    } else if (idCard.length === 18) {
      birthday = idCard.substr(6, 8);
    }
    birthday = birthday.replace(/(.{4})(.{2})/, '$1-$2-');
  }
  return birthday;
}
export function getGenderByIdCard(idCard) {
  var gender = '';
  if (parseInt(idCard.slice(-2, -1)) % 2 === 1) {
    gender = '1';
  } else {
    gender = '2';
  }
  return gender;
}
export function isIdCard(id) {
  var province = {
    11: '北京',
    12: '天津',
    13: '河北',
    14: '山西',
    15: '内蒙古',
    21: '辽宁',
    22: '吉林',
    23: '黑龙江',
    31: '上海',
    32: '江苏',
    33: '浙江',
    34: '安徽',
    35: '福建',
    36: '江西',
    37: '山东',
    41: '河南',
    42: '湖北',
    43: '湖南',
    44: '广东',
    45: '广西',
    46: '海南',
    50: '重庆',
    51: '四川',
    52: '贵州',
    53: '云南',
    54: '西藏',
    61: '陕西',
    62: '甘肃',
    63: '青海',
    64: '宁夏',
    65: '新疆',
    71: '台湾',
    81: '香港',
    82: '澳门',
    91: '国外'
  };
  var iSum = 0;
  if (!/^\d{17}(\d|x)$/i.test(id)) return false;
  id = id.replace(/x$/i, 'a');
  var provinceCode = id.substr(0, 2);
  if (!province[provinceCode]) return false;
  for (var i = 17; i >= 0; i--) {
    iSum += Math.pow(2, i) % 11 * parseInt(id.charAt(17 - i), 11);
  }
  if (iSum % 11 !== 1) return false;
  return true;
}
/** 疾病联级处理*/
export function diseaseCascade(data) {
  var parentId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '0';
  var listData = [];
  for (var i in data) {
    if (data[i].parentId.toString() === parentId.toString()) {
      var children = diseaseCascade(data, data[i].id);
      var pushData = {
        name: data[i].name,
        id: data[i].id,
        parentId: data[i].parentId
      };
      if (children.length > 0) {
        pushData.children = children;
      }
      listData.push(pushData);
    }
  }
  return listData;
}