var state = {
  isPreview: false,
  previewProjectId: undefined,
  previewPatientId: undefined
};
var mutations = {
  SET_IS_PREVIEW: function SET_IS_PREVIEW(state, v) {
    state.isPreview = v;
  },
  SET_PREVIEW_PROJECT_ID: function SET_PREVIEW_PROJECT_ID(state, v) {
    state.previewProjectId = v;
  },
  SET_PREVIEW_PATIENT_ID: function SET_PREVIEW_PATIENT_ID(state, v) {
    state.previewPatientId = v;
  }
};
var actions = {
  setIsPreview: function setIsPreview(_ref, v) {
    var commit = _ref.commit;
    commit('SET_IS_PREVIEW', v);
  },
  setPreviewProjectId: function setPreviewProjectId(_ref2, v) {
    var commit = _ref2.commit;
    commit('SET_PREVIEW_PROJECT_ID', v);
  },
  setPreviewPatientId: function setPreviewPatientId(_ref3, v) {
    var commit = _ref3.commit;
    commit('SET_PREVIEW_PATIENT_ID', v);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};