import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import _ from 'lodash';
var state = {
  fieldMap: {}
};
var mutations = {
  setMap: function setMap(state, fieldData, valueData) {
    fieldData.map(function (item) {
      item['content'] = JSON.parse(item['content']);
      var valItem = _.find(valueData, {
        ypbPatientBmiFieldId: item['id'] + ''
      });
      var valContent = valItem ? valItem.content : undefined;
      var valId = valItem ? valItem.id : undefined;
      state.fieldMap[item.id] = {
        ypbPatientBmiFieldId: item['id'] + '',
        content: valContent,
        id: valId
      };
      return item;
    });
  }
};
var actions = {};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};