//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ThemePicker from '@/components/ThemePicker';
export default {
  components: {
    ThemePicker: ThemePicker
  },
  data: function data() {
    return {
      activeColor: this.$store.state.settings.theme
    };
  },
  computed: {
    theme: function theme() {
      return this.$store.state.settings.theme;
    },
    themeStyle: function themeStyle() {
      return this.$store.state.settings.themeStyle;
    },
    fixedHeader: {
      get: function get() {
        return this.$store.state.settings.fixedHeader;
      },
      set: function set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'fixedHeader',
          value: val
        });
      }
    },
    topNav: {
      get: function get() {
        return this.$store.state.settings.topNav;
      },
      set: function set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'topNav',
          value: val
        });
        if (!val) {
          this.$store.commit('permission/SET_SIDEBAR_ROUTERS', this.$store.state.permission.defaultRoutes);
        }
      }
    },
    tagsView: {
      get: function get() {
        return this.$store.state.settings.tagsView;
      },
      set: function set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'tagsView',
          value: val
        });
      }
    },
    sidebarLogo: {
      get: function get() {
        return this.$store.state.settings.sidebarLogo;
      },
      set: function set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'sidebarLogo',
          value: val
        });
      }
    }
  },
  methods: {
    startupCallcenter: function startupCallcenter() {
      window.open('https://txwebphone.7moor.com/', 'targetWindow', "toolbar=no,\n                                    location=no,\n                                    status=no,\n                                    menubar=no,\n                                    scrollbars=yes,\n                                    resizable=yes,\n                                    width=350,\n                                    height=500");
    },
    themeChange: function themeChange(val) {
      this.activeColor = val;
      this.$store.dispatch('settings/changeSetting', {
        key: 'theme',
        value: val
      });
    },
    handleTheme: function handleTheme(val) {
      this.$store.dispatch('settings/changeSetting', {
        key: 'themeStyle',
        value: val
      });
    }
  }
};