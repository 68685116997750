var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-wrapper",
      class: _vm.classObj,
      style: { "--current-color": _vm.$store.state.settings.theme },
    },
    [
      !_vm.isIframe
        ? _c(
            "div",
            [
              _vm.device === "mobile" && _vm.sidebar.opened
                ? _c("div", {
                    staticClass: "drawer-bg",
                    on: { click: _vm.handleClickOutside },
                  })
                : _vm._e(),
              _c("sidebar", {
                staticClass: "sidebar-container",
                style: {
                  backgroundColor:
                    _vm.$store.state.settings.themeStyle === "dark"
                      ? _vm.variables.menuBg
                      : _vm.variables.menuLightBg,
                },
              }),
              _c(
                "div",
                {
                  staticClass: "main-container",
                  class: { hasTagsView: _vm.needTagsView },
                },
                [
                  _c(
                    "div",
                    { class: { "fixed-header": _vm.fixedHeader } },
                    [
                      _c("navbar"),
                      _vm.needTagsView ? _c("tags-view") : _vm._e(),
                    ],
                    1
                  ),
                  _c("app-main"),
                  _vm.showSettings
                    ? _c("right-panel", [_c("settings")], 1)
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isIframe ? _c("app-main") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }