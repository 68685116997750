import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
export default {
  namespaced: true,
  state: {
    keepAliveIncludes: []
  },
  mutations: {
    // 设置缓存
    SET_KEEPALIVEINCLUDES: function SET_KEEPALIVEINCLUDES(state, data) {
      var has = state.keepAliveIncludes.find(function (i) {
        return i.path === data.path;
      });
      if (!has) {
        state.keepAliveIncludes.push(data);
      }
    },
    // 删除缓存
    DELETE_KEEPALIVEINCLUDES: function DELETE_KEEPALIVEINCLUDES(state, data) {
      state.keepAliveIncludes = state.keepAliveIncludes.filter(function (i) {
        return i.always;
      });
    }
  }
};