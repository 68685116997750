import _objectSpread from "/root/workspace/operator_ui_preview/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { asyncRoutes, constantRoutes } from '@/router';
import { getRoutes } from '@/api/admin/sys-role';
import Layout from '@/layout';
// import sysuserindex from '@/views/sysuser/index'

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(function (role) {
      return route.meta.roles.includes(role);
    });
  } else {
    return true;
  }
}

/**
 * Use names to determine if the current user has permission
 * @param names
 * @param route
 */
function hasPathPermission(paths, route) {
  if (route.path) {
    return paths.some(function (path) {
      return route.path === path.path;
    });
  } else {
    return true;
  }
}

/**
  * 后台查询的菜单数据拼装成路由格式的数据
  * @param routes
  */
export function generaMenu(routes, data) {
  data.forEach(function (item) {
    var menu = {
      path: item.path,
      component: item.component === 'Layout' ? Layout : loadView(item.component),
      hidden: item.visible !== '0',
      children: [],
      name: item.menuName,
      meta: {
        title: item.title,
        icon: item.icon,
        noCache: item.noCache
      }
    };
    if (item.children) {
      generaMenu(menu.children, item.children);
    }
    routes.push(menu);
  });
}
export var loadView = function loadView(view) {
  // 路由懒加载
  return function (resolve) {
    return require(["@/views".concat(view)], resolve);
  };
};

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = _objectSpread({}, route);
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles);
      }
      res.push(tmp);
    }
  });
  return res;
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param components
 */
export function filterAsyncPathRoutes(routes, paths) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = _objectSpread({}, route);
    if (hasPathPermission(paths, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncPathRoutes(tmp.children, paths);
      }
      res.push(tmp);
    }
  });
  return res;
}
var state = {
  routes: [],
  addRoutes: [],
  defaultRoutes: [],
  topbarRouters: [],
  sidebarRouters: [],
  isIntercept: false,
  // 是否进行拦截
  interceptData: {
    pageData: null,
    // 页面信息
    routeData: null // 路由信息
  } // 拦截时存储toPath信息
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = constantRoutes.concat(routes);
  },
  SET_DEFAULT_ROUTES: function SET_DEFAULT_ROUTES(state, routes) {
    state.defaultRoutes = constantRoutes.concat(routes);
  },
  SET_TOPBAR_ROUTES: function SET_TOPBAR_ROUTES(state, routes) {
    // 顶部导航菜单默认添加统计报表栏指向首页
    // const index = [{
    //   path: 'dashboard',
    //   meta: { title: '统计报表', icon: 'dashboard' }
    // }]
    state.topbarRouters = routes; // .concat(index)
  },
  SET_SIDEBAR_ROUTERS: function SET_SIDEBAR_ROUTERS(state, routes) {
    state.sidebarRouters = routes;
  },
  SET_INTERCEPT: function SET_INTERCEPT(state, data) {
    state.isIntercept = data.isIntercept;
    state.interceptData.pageData = data.pageData || state.interceptData.pageData;
    state.interceptData.routeData = data.routeData || state.interceptData.routeData;
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref, roles) {
    var _this = this;
    var commit = _ref.commit;
    return new Promise(function (resolve) {
      var loadMenuData = [];
      getRoutes().then(function (response) {
        // console.log(JSON.stringify(response))
        var data = response;
        if (response.code !== 200) {
          _this.$message({
            message: '菜单数据加载异常',
            type: 0
          });
        } else {
          data = response.data;
          Object.assign(loadMenuData, data);
          generaMenu(asyncRoutes, loadMenuData);
          asyncRoutes.push({
            path: '*',
            redirect: '/',
            hidden: true
          });
          commit('SET_ROUTES', asyncRoutes);
          var sidebarRoutes = [];
          generaMenu(sidebarRoutes, loadMenuData);
          commit('SET_SIDEBAR_ROUTERS', constantRoutes.concat(sidebarRoutes));
          commit('SET_DEFAULT_ROUTES', sidebarRoutes);
          commit('SET_TOPBAR_ROUTES', sidebarRoutes);
          resolve(asyncRoutes);
        }
      }).catch(function (error) {
        console.log(error);
      });
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};